.page-numbers {
  float: right;
  list-style: none;
  display: flex;
}

.page-numbers > li {
  font-size: large;
  user-select: none;
  cursor: pointer;
  padding: 10px;
}

.active {
  color: red;
}
